<template>
  <li v-if="getCapabilityAble('FAMILY_VIEW') || getCapabilityAble('USER_VIEW')" class="nav-item dropdown">
    <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">My Family</a>
    <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
      <li v-if="getCapabilityAble('FAMILY_VIEW')"><router-link :class="'dropdown-item'" to="/families">Families</router-link></li>
      <li v-if="getCapabilityAble('USER_VIEW')"><router-link :class="'dropdown-item'"  to="/users">Users</router-link></li>
    </ul>
  </li>
  <li v-if="getCapabilityAble('COA_VIEW') || getCapabilityAble('TRANSACTION_TEMPLATE_VIEW') || getCapabilityAble('TRANSACTION_VIEW')  || getCapabilityAble('BUDGET_VIEW')" class="nav-item dropdown">
    <a id="dropdownSubMenu2" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">My Transaction</a>
    <ul aria-labelledby="dropdownSubMenu2" class="dropdown-menu border-0 shadow">
      <li v-if="getCapabilityAble('TRANSACTION_VIEW')"><router-link :class="'dropdown-item'"  to="/transactions">Transaction</router-link></li>
      <li v-if="getCapabilityAble('BUDGET_VIEW')"><router-link :class="'dropdown-item'"  to="/budgets">Budgets</router-link></li>
      <li v-if="getCapabilityAble('TRANSACTION_TEMPLATE_VIEW')"><router-link :class="'dropdown-item'"  to="/transaction_templates">Transaction Templates</router-link></li>
      <li v-if="getCapabilityAble('COA_VIEW')"><router-link :class="'dropdown-item'"  to="/coas">Chart of Accounts</router-link></li>
    </ul>
  </li>
  <li v-if="getCapabilityAble('EVENT_VIEW')" class="nav-item dropdown">
    <a id="dropdownSubMenu2" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">My Events</a>
    <ul aria-labelledby="dropdownSubMenu2" class="dropdown-menu border-0 shadow">
      <li v-if="getCapabilityAble('EVENT_VIEW')"><router-link :class="'dropdown-item'"  to="/events">Events</router-link></li>
    </ul>
  </li>
  <li v-if="getCapabilityAble('CHECKLIST_VIEW')" class="nav-item dropdown">
    <a id="dropdownSubMenu2" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">My Checklists</a>
    <ul aria-labelledby="dropdownSubMenu2" class="dropdown-menu border-0 shadow">
      <li v-if="getCapabilityAble('CHECKLIST_VIEW')"><router-link :class="'dropdown-item'"  to="/checklists">Checklists</router-link></li>
    </ul>
  </li>
  <li v-if="getCapabilityAble('REPORT_INCOME_STATEMENT_VIEW') || getCapabilityAble('REPORT_BALANCE_SHEET_VIEW')" class="nav-item dropdown">
    <a id="dropdownSubMenu2" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Reports</a>
    <ul aria-labelledby="dropdownSubMenu2" class="dropdown-menu border-0 shadow">
      <li v-if="getCapabilityAble('REPORT_BALANCE_SHEET_VIEW')"><router-link :class="'dropdown-item'"  to="/report_ledger">Ledger</router-link></li>
      <li v-if="getCapabilityAble('REPORT_INCOME_STATEMENT_VIEW')"><router-link :class="'dropdown-item'"  to="/report_income_statement">Income Statements</router-link></li>
      <li v-if="getCapabilityAble('REPORT_BALANCE_SHEET_VIEW')"><router-link :class="'dropdown-item'"  to="/report_balance_sheet">Balance Sheets</router-link></li>
    </ul>
  </li>
  <li v-if="getCapabilityAble('CURRENCY_VIEW')" class="nav-item dropdown">
    <a id="dropdownSubMenu2" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Currency</a>
    <ul aria-labelledby="dropdownSubMenu2" class="dropdown-menu border-0 shadow">
      <li v-if="getCapabilityAble('CURRENCY_VIEW')"><router-link :class="'dropdown-item'"  to="/currencies">Currencies</router-link></li>
    </ul>
  </li>
</template>

<script>
  import { mapState } from 'vuex'
  import { getCapability } from '../_helpers'
  export default {
    computed: {
        ...mapState('users', ['user'])
    },
    methods:{
      getCapabilityAble(action){
        return getCapability(action, this.user)
      }
    },
  }
</script>
