<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="familyName">Family Name:</label>
        <input class="form-control" required="" name="familyName" type="text" v-model="familyName">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/families">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('families', ['families', 'onRequest']),
    },
    data(){
      return {
        familyName: '',
      }
    },
    methods: {
      ...mapActions('families', ['add']),
      handleSubmit(){
        if(this.onRequest) return;
        const {familyName} = this
        this.add({ familyName })
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Family')
    },
  }
</script>
