<template>
  <Datatable v-bind:entries="families" :columns="columns" :title="'Families'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
        ...mapState('families', ['families']),
        ...mapState('users', ['user']),
        columns(){
          return [
          {name: 'familyName', text: 'Family Name'},
          {name: 'createdBy', text: 'Created By'},
          {name: 'createdDate', text: 'Created At', raw: { type: 'Moment_UTC'}},
          {name: 'modifiedBy', text: 'Modified By'},
          {name: 'modifiedDate', text: 'Modified At', raw: { type: 'Moment_UTC'}},
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                getCapability('FAMILY_EDIT', this.user) ? {event: 'edit_family', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                getCapability('FAMILY_DELETE', this.user) ? {event: 'families/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
              ]
            }
          },
        ]
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('families', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Families', getCapability('FAMILY_ADD', this.user) ? '/families/add' : '')
      this.get_all()
    },
  }
</script>
