<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="familyName">Family Name:</label>
          <input class="form-control" required="" name="familyName" type="text" v-model="family.familyName">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/families">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('families', ['family', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.family)
      },
      ...mapActions('families', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Family')
      this.get(this.$route.params.id)
    },
  }
</script>
