import { createApp } from 'vue'
import App from './app/App.vue'
import {router} from './_helpers'
import {store} from './_store'
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        libraries: ["places", "geometry"],
        language: "id"
    },
})
app.mount('#app')
